body {
  background: rgb(42, 33, 57);

  height: 100%;
  width: 100%;

  padding: 0;
  margin: 0;
  font-size: 1.5em;
}

@media screen and (max-width: 450px) {
  body {
    font-size: 0.7em;
  }
}

.App {
  text-align: center;
  padding: 16px 0;

  @media (min-width: 768px) {
    padding-top: 32px;
  }

  background: radial-gradient(
        circle,
        transparent 20%,
        #2a2139 20%,
        #2a2139 80%,
        transparent 80%,
        transparent
      )
      0% 0% / 140px 140px,
    radial-gradient(
        circle,
        transparent 20%,
        #2a2139 20%,
        #2a2139 80%,
        transparent 80%,
        transparent
      )
      70px 70px / 140px 140px,
    linear-gradient(rgba(89, 52, 209, 0.67) 2px, transparent 2px) 0px -1px / 70px
      70px,
    linear-gradient(90deg, rgba(89, 52, 209, 0.67) 2px, #2a2139 2px) -1px 0px / 70px
      70px #2a2139;
  background-size: 140px 140px, 140px 140px, 70px 70px, 70px 70px;
  background-color: #2a2139;
}

.avatar {
  width: 80px;
  margin-bottom: -20px;

  border-radius: 50%;
  position: relative;

  @media (min-width: 768px) {
    width: 160px;
    margin-bottom: -50px;
  }
}
